<template>
  <div>
    <v-row>
      <v-col>
        <h1 class="text-center">Confirmar reserva</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col
          v-if="reserva_done"
          cols="12"
          class="pb-0"
      >
        <v-alert
            type="primary"
            icon="mdi-alert-decagram"
        >Reserva realizada correctamente
        </v-alert>

        <h1>Te recomendamos...</h1>
        <v-img src="../assets/informacion-basica.jpg"></v-img>
      </v-col>


      <v-col
          v-if="!reserva_done"
          cols="12"
          class="pb-0"
      >
        <v-alert
            type="secondary"
            icon="mdi-alert-decagram"
        >
          ¡Atención! Aún no has terminado el proceso de reserva, debes confirmarla para completarlo.
        </v-alert>
      </v-col>

      <v-col
          v-if="!reserva_done"
          cols="12"
          class="pb-0"
      >
        <v-btn class="ma-0" large block color="primary" @click="save_reserva">
          Haz clic aquí para confirmar
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="centro && reserva">
      <v-col sm="12" md="6">
        <div class="titulo">Servicio</div>
        <div class="descripcion">
          {{ reserva.descripcion }}
        </div>

        <div class="titulo">Fecha</div>
        <div class="descripcion">
          {{ reserva.fecha | moment('DD/MM/YYYY') }} {{ reserva.hora }}
        </div>

        <div class="titulo">Dirección</div>
        <div class="descripcion">
          {{ centro.direccion }}
          <br>
          {{ centro.codigo_postal }} {{ centro.ciudad }}
        </div>

        <div class="titulo">Total</div>
        <div class="descripcion">
          <div v-if="descuento_online > 0">
            <span style="text-decoration: line-through; color:darkred ">{{ reserva.precioReserva | money }}</span> <br>
            <span style="font-weight: bold">{{ total | money }}</span>
          </div>
          <div v-else>
            {{ reserva.precioReserva | money }}
          </div>

        </div>
      </v-col>

      <v-col sm="12" md="6">
        <h2>{{ centro.descripcion }}</h2>
        <v-img class="imagen_centro" :src="centro.imagen_principal_absolute_path"/>
      </v-col>
    </v-row>

    <v-row v-if="reserva_done">
      <v-col>
        <v-btn large block color="primary" @click="go_to_reservas">
          <v-icon>mdi-calendar-heart</v-icon>
          Ir a mis reservas
        </v-btn>
      </v-col>
      <v-col>
        <v-btn large block color="primary" @click="logout">
          <v-icon>mdi-logout</v-icon>
          Cerrar sesión
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import show_toolbar_mixin from "@/mixins/show_toolbar_mixin";
import store from "@/store";
import send_stats_mixin from "../mixins/send_stats_mixin";
import get_utms from '../utils/utm_params'

export default {
  name: 'ConfirmarReserva',
  mixins: [show_toolbar_mixin, send_stats_mixin],

  data() {
    return {
      reserva_done: false,
      descuento_online: 0,
      utms: get_utms()
    }
  },

  methods: {
    async save_reserva() {
      try {
        let data = {...this.reserva}
        data.descuento = this.descuento_online
        data.precioReserva = parseFloat(this.total)
        if (this.utms) {
          data.utm_params = this.utms
        }
        await this.$store.dispatch('reserva/save_reserva', data)
        this.$store.commit('common/set_show_toolbar', false)
        this.$notify({
          title: 'Acción realizada correctamente',
          text: 'Reserva realizada con éxito'
        })
        this.reserva_done = true
        this.$store.commit('set_reserva_confirmada', true)
        await this.$store.dispatch('auth/me')
      } catch (err) {
        this.$notify({
          title: 'Error',
          text: 'Se ha producido un error al realizar la reserva',
          style: 'error'
        })
      }
    },

    async check_reservas_pendientes() {
      let reservas_pendientes = 0
      try {
        const user = await this.$store.dispatch('auth/me')
        user.reservas_usuario_portal.forEach(
            current_reserva => {
              if (current_reserva.estado.id === 2) {
                ++reservas_pendientes
              }
            }
        )
        if (reservas_pendientes > 0) {
          const resp = await this.$alert(
              "Para poder realizar una nueva reserva, primero tienes que anular las citas pendientes.",
              "",
              'info'
          )
          await this.$router.replace({name: "reservas"})
        }
      } catch (err) {
      }
    },

    go_to_reservas() {
      const { utm_source, utm_medium, utm_campaign } = get_utms()
      this.$router.push({
        name: 'reservas', query: {
          next: 'centros',
          utm_source: utm_source,
          utm_medium: utm_medium,
          utm_campaign: utm_campaign
        }, replace: true
      })
    },

    async logout() {
      const { utm_source, utm_medium, utm_campaign } = get_utms()
      await this.$store.dispatch('auth/logout')
      await this.$router.push({
        name: 'login', query: {
          next: 'centros',
          utm_source,
          utm_medium,
          utm_campaign
        }, replace: true
      })
    }
  },

  mounted() {
    this.check_reservas_pendientes()
    this.descuento_online = this.user.descuento_online
  },

  computed: {
    user() {
      return this.$store.state.auth.user
    },
    centro() {
      return this.$store.state.centro.centro
    },
    reserva() {
      return this.$store.state.reserva
    },
    total() {
      return (this.reserva.precioReserva - (this.descuento_online * this.reserva.precioReserva / 100)).toFixed(2)
    }
  }
}
</script>

<style lang="less" scoped>
.titulo {
  padding: 0.2em 1em;
  color: darkgray;
  font-size: 1.1em;
}

.descripcion {
  padding: 0 3em;
  color: var(--v-secondary-base);
  font-size: 1.2em;
}
</style>
